import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';

import { NAV_ITEMS } from 'utils/routes';

import styles from './LandingHeader.module.css';

const GET_LANDING_HEADER = graphql`
  query GetLandingQuery {
    headerImg: file(relativePath: { eq: "landing-header-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImg: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const LandingHeader: React.FC = () => {
  const data = useStaticQuery(GET_LANDING_HEADER);

  return (
    <header className={styles.header}>
      <div className={styles.headerBg}>
        <BackgroundImg
          fluid={data.headerImg.childImageSharp.fluid}
          critical
          className={styles.headerBgImg}
          backgroundColor="#a293d7" // dominant color to load with
        />
        <div className={styles.headerBgOverlay} />
      </div>
      <div className={styles.headerInnerContainer}>
        <div className={styles.logoNavContainer}>
          <Img
            fluid={data.logoImg.childImageSharp.fluid}
            critical
            className={styles.headerLogo}
            alt="Rock Bottom Records logo"
          />
          <nav className={styles.headerLinks}>
            {NAV_ITEMS.map(({ to, title }) => (
              <Link to={to} className={styles.headerLink} key={title}>
                {title}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
