import React from 'react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import styles from './ArtistListItem.module.css';

interface IArtistListItemProps {
  title: string;
  to: string;
  backgroundImage: FluidObject;
}

export const ArtistListItem: React.FC<IArtistListItemProps> = ({
  title,
  to,
  backgroundImage,
}) => (
  <Link to={to} className={styles.artist}>
    <div className={styles.artistOverlay} />
    <Img
      fluid={backgroundImage}
      className={styles.artistImg}
      style={{ position: 'absolute' }}
    />
    <div className={styles.artistContent}>
      <p className={styles.artistTitle}>{title}</p>
    </div>
  </Link>
);
