import React from 'react';
import { graphql } from 'gatsby';
import { LANDING_SEO } from 'utils/seo';

import Layout from 'components/layout';
import { Landing } from 'components/Landing';

import { GetLandingPageQuery } from 'types';

interface ILandingPageProps {
  data: GetLandingPageQuery;
  location: Location;
}

export const LandingPage: React.FC<ILandingPageProps> = ({
  data: { artists, releases, galleryImages },
}) => (
  <Layout
    {...LANDING_SEO}
    overrideTitle
    layoutClassName="overflow-x-hidden"
    navProps={{ hideUntilScroll: true }}
  >
    <Landing
      artists={artists.edges}
      releases={releases.edges}
      images={galleryImages.image}
    />
  </Layout>
);

export default LandingPage;

export const landingPageQuery = graphql`
  query GetLandingPage {
    artists: allContentfulArtist(
      sort: { fields: [createdAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          slug
          name
          images {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }

    releases: allContentfulRelease(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
          coverArt {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 80)
            }
          }
          artists {
            name
            slug
          }
        }
      }
    }

    galleryImages: contentfulGallery(placement: { eq: "Homepage" }) {
      image {
        id
        description
        fluid(maxWidth: 400) {
          # TODO: Optimize this with gatsby-image
          src
        }
      }
    }
  }
`;
