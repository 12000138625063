import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import cx from 'classnames';
import { RELEASES_ROUTE, RELEASE_DETAILS_ROUTE } from 'utils/routes';
import { GetLandingPageQuery } from 'types';

import styles from '../LandingReleases.module.css';

interface ILatestRelease {
  release: GetLandingPageQuery['releases']['edges'][0];
}

export const LatestRelease: React.FC<ILatestRelease> = ({ release }) => (
  <div className={styles.releasesLatestContainer}>
    <div className={styles.releasesLatestImgContainer}>
      <Link
        to={RELEASE_DETAILS_ROUTE(release.node.slug)}
        className={styles.releaseLatestImgLink}
      >
        <Img
          fluid={release.node.coverArt.fluid}
          className={styles.releasesLatestImg}
          alt={`${release.node.title} cover art`}
        />
      </Link>
    </div>
    <div className={styles.releasesLatestActions}>
      <Link
        to={RELEASE_DETAILS_ROUTE(release.node.slug)}
        className={cx(styles.releasesLatestSoundcloudBtn)}
      >
        Listen Now
      </Link>
      <Link to={RELEASES_ROUTE} className={styles.btn}>
        See all releases
      </Link>
    </div>
  </div>
);
