import React from 'react';
import { GetLandingPageQuery } from 'types';

import LandingHeader from './LandingHeader';
import LandingArtists from './LandingArtists';
import LandingReleases from './LandingReleases';
import LandingGallery from './LandingGallery';

interface ILandingProps {
  artists: GetLandingPageQuery['artists']['edges'];
  releases: GetLandingPageQuery['releases']['edges'];
  images: GetLandingPageQuery['galleryImages']['image'];
}

export const Landing: React.FC<ILandingProps> = ({
  artists,
  releases,
  images,
}) => {
  return (
    <>
      <LandingHeader />
      <LandingArtists artists={artists} />
      <LandingReleases releases={releases} />
      <LandingGallery images={images} />
    </>
  );
};
