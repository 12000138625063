import React from 'react';
import { RELEASE_DETAILS_ROUTE } from 'utils/routes';
import { GetLandingPageQuery } from 'types';

import { LatestRelease } from './LatestRelease';
import { LandingReleaseItem } from './LatestReleaseItem';

import styles from './LandingReleases.module.css';

interface ILandingReleasesProps {
  releases: GetLandingPageQuery['releases']['edges'];
}

const LandingReleases: React.FC<ILandingReleasesProps> = ({ releases }) => (
  <section className={styles.releases}>
    <div className={styles.releasesLatestSection}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>Releases</h2>
        <h3 className={styles.sectionSubtitle}>Our Latest Track</h3>
      </div>
      <LatestRelease release={releases[0]} />
    </div>
    <div className={styles.releasesPreviousSection}>
      <div className={styles.releasesPreviousInnerContainer}>
        <h3 className={styles.releasesPreviousTitle}>Explore More Releases</h3>
        <div className={styles.releasesPreviousItems}>
          {releases
            .slice(1, 4)
            .map(({ node: { id, title, slug, coverArt, description } }) => (
              <LandingReleaseItem
                key={id}
                title={title}
                description={description.childMarkdownRemark.excerpt}
                img={coverArt.fluid}
                to={RELEASE_DETAILS_ROUTE(slug)}
              />
            ))}
        </div>
      </div>
    </div>
  </section>
);

export default LandingReleases;
