import React from 'react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { ContentfulRelease } from 'types';

import styles from '../LandingReleases.module.css';

interface ILandingReleaseItemProps {
  title: ContentfulRelease['title'];
  to: string;
  description: string;
  img: FluidObject;
}

export const LandingReleaseItem: React.FC<ILandingReleaseItemProps> = ({
  title,
  to,
  description,
  img,
}) => (
  <article className={styles.release}>
    <div className={styles.releaseImgContainer}>
      <Link to={to} className={styles.releaseImgLinkContainer}>
        <Img
          fluid={img}
          alt={`${title} cover art`}
          className={styles.releaseImg}
        />
      </Link>
    </div>
    <div className={styles.releaseInfo}>
      <h3 className={styles.releaseTitle}>
        <Link to={to} className={styles.releaseTitleLink}>
          {title}
        </Link>
      </h3>
      <p
        dangerouslySetInnerHTML={{ __html: description }}
        className={styles.releaseDescription}
      />
    </div>
  </article>
);
