import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Slider, { Settings as ReactSlickSettings } from 'react-slick';

import styles from './Gallery.module.css';

interface IArrowProps {
  onClick?: () => void;
}

const NextArrow: React.FC<IArrowProps> = ({ onClick }) => (
  <button onClick={onClick} className={styles.nextArrow}>
    <svg
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.arrowIcon}
    >
      <path
        d="M0 15.188h20.503l-9.45 9.45L13.5 27 27 13.5 13.5 0l-2.362 2.362 9.365 9.45H0z"
        fill="#231F20"
      />
    </svg>
  </button>
);

const PrevArrow: React.FC<IArrowProps> = ({ onClick }) => (
  <button onClick={onClick} className={styles.prevArrow}>
    <svg
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.arrowIcon}
    >
      <path
        d="M27 15.188H6.497l9.45 9.45L13.5 27 0 13.5 13.5 0l2.362 2.362-9.365 9.45H27z"
        fill="#231F20"
      />
    </svg>
  </button>
);

const Gallery: React.FC<ReactSlickSettings> = ({ children, ...props }) => {
  const settings = {
    infinite: true,
    arrows: true,
    centerMode: true,
    swipeToSlide: true,
    preventScrollOnTouch: true,
    dots: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...props,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default Gallery;
