import React from 'react';
import cx from 'classnames';
import Gallery from 'components/common/Gallery';
import { GetLandingPageQuery } from 'types';

import styles from './LandingGallery.module.css';

interface ILandingGalleryProps {
  images: GetLandingPageQuery['galleryImages']['image'];
}

const LandingGallery: React.FC<ILandingGalleryProps> = ({ images }) =>
  images && (
    <section className={cx(styles.section, styles.fullBleed)}>
      <h2 className={cx(styles.sectionTitle, 'tc')}>Life of a Bottomfeeder</h2>
      <Gallery>
        {images.map(({ id, description, fluid: { src } }) => (
          <div className={styles.galleryImgContainer} key={id}>
            <img
              className={styles.galleryImg}
              src={src}
              alt={description}
              title={description}
            />
          </div>
        ))}
      </Gallery>
    </section>
  );

export default LandingGallery;
