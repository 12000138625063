import React from 'react';
import { Link } from 'gatsby';
import get from 'lodash/get';
import { ARTISTS_ROUTE, ARTIST_DETAILS_ROUTE } from 'utils/routes';
import { ArtistListItem } from 'components/Artists/ArtistListItem';
import { GetLandingPageQuery } from 'types';

import styles from '../Landing.module.css';

interface ILandingArtists {
  artists: GetLandingPageQuery['artists']['edges'];
}

const LandingArtists: React.FC<ILandingArtists> = ({ artists }) => (
  <section className={styles.section}>
    <div className={styles.sectionHeader}>
      <h2 className={styles.sectionTitle}>Our Artists</h2>
      <p className={styles.sectionCopy}>Our artists are our mission.</p>
      <Link to={ARTISTS_ROUTE} className={styles.btn}>
        See all artists
      </Link>
    </div>
    <div className={styles.artists}>
      {artists.map(({ node: { name, slug, images } }) => (
        <ArtistListItem
          key={slug}
          to={ARTIST_DETAILS_ROUTE(slug)}
          title={name}
          backgroundImage={get(images[0], 'fluid', '')}
        />
      ))}
    </div>
  </section>
);

export default LandingArtists;
